<div class="input-group">
	<tb-control-label [id]="id" [label]="label" [required]="required" [invalid]="invalid" [tooltip]="tooltip" />

	<p-inputSwitch
    id="{{ id }}"
    [(ngModel)]="value"
    [disabled]="disabled"
		(onChange)="onValueChanged($event)"
    (blur)="onTouched()"
    [required]="required"
		[ngClass]="{ 'ng-invalid': invalid }"
    class="w-full"
  />

	<div *ngIf="!hideErrorMessages" class="input-error-message"></div>
</div>
