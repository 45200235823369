import { Component, EventEmitter, forwardRef, Output, ViewEncapsulation } from '@angular/core'
import { InputTextModule } from 'primeng/inputtext'
import { NgClass, NgIf } from '@angular/common'
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms'
import { InputSwitchChangeEvent, InputSwitchModule } from 'primeng/inputswitch'
import { BaseFormControlComponent } from '@app/components/base/base-form-control.component'
import { ControlLabelComponent } from '@app/components/uiux/control-label/control-label.component'

@Component({
  selector: 'tb-switch',
  standalone: true,
  templateUrl: './switch.component.html',
  styleUrl: './switch.component.scss',
  encapsulation: ViewEncapsulation.None,
  imports: [
    InputTextModule,
    NgIf,
    ReactiveFormsModule,
    InputSwitchModule,
    NgClass,
    FormsModule,
    ControlLabelComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true
    },
  ],
})
export class SwitchComponent extends BaseFormControlComponent {

  @Output() onValueChange: EventEmitter<any> = new EventEmitter<any>()

  // emit event
  onValueChanged(event: InputSwitchChangeEvent): void {
    this.onValueChange.emit(this.value)
    this.onChange(this.value)
  }

}
